﻿@import "../_mixins";

#Accordion .main();

/* Wizard */
.wizard-bubble-active {
    //border-color: @telekomBlue;
    border-color:@telekomGrey2;
}

.wizard-bubble-active {
    &:hover {
        //border-color: @telekomBlueLight;
        border-color:@telekomGrey1;
    }
}

.wizard-bubble-current {
    //border-color: @telekomBlueActive;
    border-color:@telekomGrey3;
}

.wizard-bubble-small-active {
    //background-color: @telekomBlue;
    background-color: @telekomGrey2;

    &:hover, &:active, &:focus {
        //background-color: @telekomBlueLight;
        //border-color: @telekomBlueLight;
        background-color: @telekomGrey1;
        border-color: @telekomGrey1;
    }
}

.wizard-bubble-small-current {
    //border-color: @telekomBlueActive;
    border-color: @telekomGrey3;
    background: white;

    &:hover {
        //border-color: @telekomBlueActive;
        border-color: @telekomGrey3;
        background: white;
    }
}

.connecting-line-active {
    //background-color: @telekomBlue;
    background-color:@telekomGrey2;
}

iframe.datasheet {
    border: 1px solid #ced4da;
}

input[type=radio] {
    &:checked {
        & ~ .radio-bubble {
            border-color: @telekomBlue;
        }
    }

    &:hover {
        & ~ .radio-bubble {
            border-color: @telekomBlueLight;
        }
    }
}

.select2 {
    .selection {
        .natco-img-flag {
            box-shadow: 0px 0px 3px 1px #7f7f7f;
        }
    }
}

#selectTariffCountryFlags {
    .natco-img-flag {
        .flag-wrapper {
            img {
                box-shadow: 0px 0px 3px 1px #7f7f7f;
            }
        }
    }
}

.summary-container {
    .natco-img-flag {
        box-shadow: 0px 0px 3px 1px #7f7f7f;
    }

    .btn {
        min-width: 200px;
    }
}

/* Payload & Protocols */

.payload-draggable {
    color: white;
    // Same border as other modals
    border: 1px solid rgba(0,0,0,.2);

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(255, 141, 200, 0.5) !important;
    }
}

.uplink-draggable {
    background:rgb(0, 112, 192);
}

.downlink-draggable {
    background: rgb(79, 163, 239);
}

.ui-slider {
    .ui-slider-handle {
        //background-color: @telekomBlue !important;
        background-color: @telekomGrey3 !important;

        &.ui-state-focus, &.ui-state-active {
            //box-shadow: 0 0 0 0.2rem fade(@telekomBlueLight, 50%);
            //background-color: @telekomBlueActive !important;
            box-shadow: 0 0 0 0.2rem fade(@telekomGrey1, 50%);
            background-color: @telekomGrey2 !important;
        }
    }

    .ui-slider-range {
        //background: @telekomBlueLight;
        background: @telekomGrey1;
    }
}

#payload-settings {
    background: rgb(226,0,116);
    color: white;
    // Same border as other modals
    border: 1px solid rgba(0,0,0,.2);
}

// Small modal with a layout similar to technology cards
#payload-modal {
    color: white;
    //background: @telekomBlue;
    background:@telekomGrey5;
    border: 1px solid rgba(0,0,0,.2);

    a, a:focus, a:hover, a:active, a:link {
        color: white;
    }
}

.disclaimer-text {
    color: #D67600;
}

#header-size-container {
    background: @telekomGrey5;

    label {
        color: white;
    }
}

#panelBatterySelectionGuideLink, #MicroOptimizationHintContainer {
    background: dimgrey;
    color: white;
    
    a {
        color: white;
    }
}

.battery-warning {
    font-size: 1rem;
    color: @telekomBlue !important;
    text-align: center;
    padding-top: 0.5rem;
}

.handle-value {
    input {
        color: @telekomGrey5;
    }
}

#modalBatterySelectionGuide {   
    fieldset {
        border: 1px solid #e3e3e3;
        -webkit-box-shadow: 2px 2px 0px 1px rgba(0, 0, 0, 0.05);
        box-shadow: 2px 2px 0px 1px rgba(0, 0, 0, 0.05);
    }

    legend {
        color: #333;
        background: #fff;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
        -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.05);
    }
}

.atcommandname {
    color: @telekomBlue !important;
}

.reconfiguration-icon {
    color: @telekomGrey5;
}