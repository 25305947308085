﻿@import "../_mixins";

#adminToolbar {
    &.dx-toolbar {
        .dx-button {
            &.dx-state-hover {
                box-shadow: 0px 0px 5px 2px @telekomGrey3;
            }
        }
    }
}

table {
    &.property-table {
        .add-json-value-button {
            &:hover {
                color: @telekomGrey2;
            }
        }
    }
}