﻿@import "_mixins";

.telekom-only {
    display:none;
}

html {
    body {
        // Header:
        nav.bg-dark {
            background-color: @telekomGrey5 !important;
        }
        // Buttons:
        .btn-outline-primary {
            //color: @telekomBlue;
            //border-color: @telekomBlue;
            color: @telekomGrey5;
            border-color: @telekomGrey5;

            &:hover {
                //background: @telekomBlue;
                //border-color: @telekomBlue;
                background: @telekomGrey5;
                border-color: @telekomGrey5;
                box-shadow: 0 0 5px rgba(0,0,0,0.3);
                color: white;
            }

            &:active, &:focus {
                //border-color: @telekomBlue !important;
                border-color: @telekomGrey5;
                //box-shadow: 0 0 0 0.2rem fade(@telekomBlueLight, 50%) !important;
                //box-shadow: 0 0 0 0.2rem fade(@telekomGrey3, 50%) !important;
                box-shadow: 0 0 5px rgba(0,0,0,0.3) !important;
                //background: @telekomBlue !important;
                background: @telekomGrey5;
                color: white;
            }
        }

        .dropdown.show > .btn-outline-primary.dropdown-toggle {
            color: white !important;
            background: @telekomGrey5;
            border-color: @telekomGrey5;
            box-shadow: 0 0 0 0.2rem fade(@telekomGrey3, 50%) !important;
        }

        .btn-primary {
            color: white;
            //border-color: @telekomBlueDark;
            //background: @telekomBlue;
            border-color: @telekomGrey5;
            background: @telekomGrey5;

            &:hover {
                //background: @telekomBlueLight;
                //border-color: @telekomBlueDark;
                color: @telekomGrey5;
                border-color: @telekomGrey5;
                background: white;
            }

            &:active, &:focus, &:not(:disabled):not(.disabled):active {
                //border-color: @telekomBlue !important;
                border-color: @telekomGrey5 !important;
                //box-shadow: 0 0 0 0.2rem fade(@telekomBlueLight, 50%) !important;
                box-shadow: 0 0 0 0.2rem fade(@telekomGrey5, 50%) !important;
                //background: @telekomBlue !important;
                background: white;
                color: @telekomGrey5;
            }
        }
        // Inputs:
        .form-control {
            &:not([disabled]):hover {
                //border-color: @telekomBlue;
                //color: @telekomBlue;
                border-color: @telekomGrey3;
                color: @telekomGrey3;
                background: white;
            }

            &:not([disabled]):active, &:not([disabled]):focus {
                //border-color: @telekomBlue;
                //color: @telekomBlue;
                //box-shadow: 0 0 0 0.2rem fade(@telekomBlueLight, 50%);
                border-color: @telekomGrey2;
                color: @telekomGrey2;
                box-shadow: 0 0 0 0.2rem fade(@telekomGrey1, 50%);
            }
        }
        //Headlines:
        .main-header {
            h1, h2, h3 {
                color: @telekomGrey5;
            }
        }
        //Switches:
        input[type="checkbox"].checkbox-switch {
            & ~ label {
                &:before {
                    //background: @telekomBlueLight;
                    background: @telekomGrey4;
                    border: 1px solid #d9d9d9;
                }

                &:after {
                    background: #fff;
                    border: 1px solid #d9d9d9;
                }

                &:hover:after {
                    box-shadow: 0 0 5px rgba(0,0,0,0.3);
                }
            }

            &:checked ~ label {
                &:before {
                    //background: @telekomBlue;
                    background: @telekomGrey5;
                }
            }
        }

        .ui-widget-content {
            a {
                color: @telekomGrey5;

                &.btn-outline-primary {
                    color: @telekomGrey5;

                    &:hover, &:active, &:focus {
                        color: white;
                    }
                }
            }
        }
    }
}

.ui-widget-content {
    a {
        color: @telekomGrey5;

        &.btn-outline-primary {
            color: @telekomGrey5;

            &:hover, &:active, &:focus {
                color: white;
            }
        }
    }
}

// Modal windows:
.modal-content {
    background-color:@telekomGrey3;
}

#ModalRateProduct .modal-content {
    background-color: white;        
}

// Accordions:
.accordion-no-data {
    background-color: @telekomGrey5 !important;
    border-color: @telekomGrey5 !important;
}

.accordion-title.level_0 {
    background-color: @telekomGrey5 !important;
    border-color: @telekomGrey5 !important;
}

.accordion-title.level_1, .accordion-title.level_2 {
    color: @telekomGrey5 !important;
}

.accordion-title {
    &.level_1, &.level_2 {
        .ui-icon-triangle-1-e, .ui-icon-triangle-1-s {
            color:@telekomGrey5 !important;
        }
    }
}

// Unit input groups:
.unit-input-group {
    input, select {
        &:hover + div > span.input-group-text {
            //border-color: @telekomBlue;
            border-color: @telekomGrey3;
        }
    }

    input[readonly], input[disabled], select[readonly], select[disabled] {
        & + .input-group-append > span.input-group-text {
            background-color: #e9ecef;
        }

        &:hover + .input-group-append > span.input-group-text {
            color: unset;
            border-color: #ced4da;
        }
    }
}
// Checkboxen:
input[type='checkbox']:not(.checkbox-switch) {
    &:checked:before {
        //background: @telekomBlue;
        background: @telekomGrey3;
    }

    &:enabled:hover, &:enabled:focus, &:enabled:active {
        border: none;
        outline: none;

        &:before {
            border-color: @telekomGrey3;
            outline-color: @telekomGrey3;
        }
    }

    &:enabled:hover:before {
        background: @telekomGrey2;
    }

    &:disabled:before {
        background: @telekomGrey1;
    }
}
// Navigation bubbles (admin area):
#panelDevices, #panelManageProjects {
    a.nav-bubble-list-item {
        .nav-bubble {
            &.active {
                //border-color: @telekomBlue;
                border-color: @telekomGrey3;
            }
        }

        &:hover, &:active, &:focus {
            .nav-text {
                //color: @telekomBlue;
                color: @telekomGrey3;
            }
        }
    }
}

.left-nav-container {
    .left-nav-bubble {
        border-color: @telekomGrey5;
    }
}

.header-with-icon-container {
    > div {
        border-color: @telekomGrey5 !important;
    }
}

.main-header-container {
    .icon-container {
        .icon-bubble {
            border-color: @telekomGrey5;
        }
    }
}

.dropdown-item {
    &.active, &:active, &:focus {
        background-color:@telekomGrey5;
    }
}

// Diagrams (micro optimizations, payload, project summary report)
svg {
    .axis {
        path {
            stroke: @telekomGrey5;
        }
    }

    #xa-arrowhead, #ya-arrowhead {
        fill: @telekomGrey5 !important;
    }

    .deviation {
        line {
            stroke: @telekomGrey5;
        }
    }

    .sublabel {
        text {
            &, tspan {
                fill: black !important;
            }
        }
    }

    .currentUseCase {
        stroke: @telekomGrey5;
    }

    .currentusecaselabel, .currentUseCasePath {
        fill: @telekomGrey5 !important;
    }

    .reduction {
        fill: @telekomGrey5 !important;
        stroke: @telekomGrey5 !important;
    }
}

// Loading circle
.iot-loader, .iot-chart-loader {
    .iot-loader-spinner {
        border-top-color: @telekomGrey2 !important;
    }
}

// Customer profile card
#CustomerProfileChartGrid {
    #CustomerProfileChart {
        background-image: url("/images/customer-profile-card-background.neutral.png");
    }
}

// Neutral highlight glow
span.highlighted-text {
    text-shadow: 0 0 5px @telekomBlueLight !important;
}

svg a text.highlighted {
    fill: @telekomBlueLight !important;
}

#panelDevices a.highlighted .nav-bubble {
    box-shadow: 0 0 6px 6px @telekomBlueLight !important;
}

// Navbar + Breadcrumbs
.nav-item {
    a:not(.dropdown-item):hover, #navbar-label {
        color: @telekomLink;
    }
}

#main-navbar #main-navlist #navbar-label::after {
    border-color: @telekomLink;
}

.breadcrumb-item a:hover {
    color: @telekomLink;
}

// Star rating
.product-rating, #RateProduct {
    .rate-base-layer,
    .rate-hover-layer,
    .rate-select-layer {
        color: @telekomGrey2 !important;
    }
}

// Styles overrides for select2
.select2-container--bootstrap4 {
    .select2-selection--single {       
        &:hover {
            border-color: @telekomGrey3 !important;
            color: @telekomGrey3 !important;
            background: white;
        }

        &:active, &:focus {
            border-color: @telekomGrey2 !important;
            color: @telekomGrey2 !important;
            background: white;
        }
    }

    .select2-dropdown {
        border-color: @telekomGrey3;
    }
}

.select2-container--bootstrap4.select2-container--focus:not(.select2-container--open) {
    .select2-selection {
        border-color: @telekomGrey3 !important;

        &:active, &:focus {
            box-shadow: 0 0 0 0.2rem fade(@telekomGrey1, 50%) !important;
        }
    }
}

.select2-container--bootstrap4.select2-container--open .select2-selection {
    border-color: @telekomGrey3;
}